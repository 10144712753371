import { lazy } from "react";

export default [
  {
    title: "Account Page",
    component: lazy(() => import("./Account")),
    url: "/client/account-settings",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Edit Profile",
    component: lazy(() => import("./EditProfile")),
    url: "/client/edit-profile",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Edit Security",
    component: lazy(() => import("./EditSecurity")),
    url: "/client/edit-security-preferences",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Edit Conatct",
    component: lazy(() => import("./EditContact")),
    url: "/client/edit-contact-preferences",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Automated Messaging",
    component: lazy(() => import("./AutoMessaging")),
    url: "/client/edit-automated-messaging",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Account History",
    component: lazy(() => import("./AccountHistory")),
    url: "/client/account-history",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
  {
    title: "Email verification",
    component: lazy(() => import("./VerificationCode")),
    url: "/client/otp-verification",
    requirePermission: true,
    roles: ["Super_Admin", "Admin", "Client"],
  },
];
