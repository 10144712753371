import dayjs from "dayjs";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getAdminCompletedCallback,
  getAdminTodayCallback,
  getAdminUnattendedCallback,
} from "../services/api/QuestionnarieApi";
import _ from "lodash";

type FilterState = {
  searchText: string;
  services: string[];
  categories: string[];
  rightcareTriggers: string[];
  safety: string;
  status: string;
  ageFrom?: number;
  ageTo?: number;
  dateFrom: string;
  dateTo: string;
};

interface CallbacksState {
  data: any[];
  completedData: any[];
  unattendedData: any[];
  loading: boolean;
  error: string | null;
  pagination: {
    page: number;
    rowPerPage: number;
    totalRows: number;
  };
  filter: {
    0: FilterState;
    1: FilterState;
    2: FilterState;
  };
}

const cleanFilter = (filter: Record<string, any>) => {
  return Object.keys(filter).reduce((acc, key) => {
    const value = filter[key];
    if (
      value !== undefined &&
      value !== null &&
      value !== "" &&
      value.length !== 0
    ) {
      acc[key] = value;
    }
    return acc;
  }, {} as Record<string, any>);
};

// Thunk to fetch all callback data
export const fetchTodayAllCallbacks = createAsyncThunk(
  "callbacks/fetchAll",
  async (
    {
      pagination,
      filter,
    }: {
      pagination: { pageNo: number; perPage: number };
      filter: {
        searchText?: string;
        services?: string[];
        categories?: string[];
        rightcareTriggers?: string[];
        safety?: string;
        status?: string;
        ageFrom?: number;
        ageTo?: number;
        dateFrom?: string;
        dateTo?: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const today = dayjs().format("YYYY-MM-DD");
      const categories = filter.categories || [
        "morning",
        "afternoon",
        "evening",
        "anytime",
      ];

      const allResponses = await Promise.all(
        categories.map(async (category) => {
          const cleanedFilter = cleanFilter({
            searchText: filter.searchText?.trim(),
            services: filter.services,
            categories: category,
            rightcareTriggers: filter.rightcareTriggers,
            safety: filter.safety,
            status: filter.status,
            ageFrom: filter.ageFrom,
            ageTo: filter.ageTo,
            dateFrom: today,
            dateTo: today,
          });

          const response = await getAdminTodayCallback(
            cleanedFilter,
            pagination
          );
          return { category, data: response };
        })
      );

      return _.flatMap(allResponses, "data");
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to fetch completed callbacks
export const fetchCompletedCallbacks = createAsyncThunk(
  "callbacks/fetchCompleted",
  async (
    {
      pagination,
      filter,
    }: {
      pagination: { pageNo: number; perPage: number };
      filter: {
        searchText?: string;
        services?: string[];
        categories?: string[];
        rightcareTriggers?: string[];
        safety?: string;
        status?: string;
        ageFrom?: number;
        ageTo?: number;
        dateFrom?: string;
        dateTo?: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      const cleanedFilter = cleanFilter({
        searchText: filter.searchText?.trim(),
        services: filter.services,
        categories: filter.categories,
        rightcareTriggers: filter.rightcareTriggers,
        safety: filter.safety,
        status: filter.status,
        ageFrom: filter.ageFrom,
        ageTo: filter.ageTo,
        dateFrom: filter.dateFrom,
        dateTo: filter.dateTo,
      });

      const response = await getAdminCompletedCallback(
        cleanedFilter,
        pagination
      );

      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Thunk to fetch unattended callbacks
export const fetchUnattendedCallbacks = createAsyncThunk(
  "callbacks/fetchUnattended",
  async (
    {
      pagination,
      filter,
    }: {
      pagination: { pageNo: number; perPage: number };
      filter: {
        searchText?: string;
        services?: string[];
        categories?: string[];
        rightcareTriggers?: string[];
        safety?: string;
        status?: string;
        ageFrom?: number;
        ageTo?: number;
        dateFrom?: string;
        dateTo?: string;
      };
    },
    { rejectWithValue }
  ) => {
    try {
      // Get the previous date
      const prevDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");

      const cleanedFilter = cleanFilter({
        searchText: filter.searchText?.trim(),
        services: filter.services,
        categories: filter.categories,
        rightcareTriggers: filter.rightcareTriggers,
        safety: filter.safety,
        status: filter.status,
        ageFrom: filter.ageFrom,
        ageTo: filter.ageTo,
        dateFrom: filter.dateFrom,
        dateTo: prevDate,
      });

      const response = await getAdminUnattendedCallback(
        cleanedFilter,
        pagination
      );
      return response;
    } catch (error: any) {
      return rejectWithValue(error.message);
    }
  }
);

// Define the initial filter state for each tab
const initialFilterState = {
  searchText: "",
  services: [],
  categories: [],
  rightcareTriggers: [],
  safety: "",
  status: "",
  ageFrom: undefined,
  ageTo: undefined,
  dateFrom: "",
  dateTo: "",
};

const initialState: CallbacksState = {
  data: [],
  completedData: [],
  unattendedData: [],
  loading: false,
  error: null,
  pagination: {
    page: 1,
    rowPerPage: 10,
    totalRows: 0,
  },
  filter: {
    0: { ...initialFilterState },
    1: { ...initialFilterState },
    2: { ...initialFilterState },
  },
};

const callbackSlice = createSlice({
  name: "callbacks",
  initialState,
  reducers: {
    setPage: (state, action) => {
      state.pagination.page = action.payload;
    },
    setRowPerPage: (state, action) => {
      state.pagination.rowPerPage = action.payload;
    },
    resetPagination: (state) => {
      state.pagination.page = 1;
      state.pagination.rowPerPage = 10; // default or initial value
      state.pagination.totalRows = 0;
    },
    setFilter: (
      state,
      action: {
        payload: {
          selectedTab: keyof CallbacksState["filter"];
          filter: FilterState;
        };
      }
    ) => {
      const { selectedTab, filter } = action.payload;
      state.filter[selectedTab] = filter;
    },
    resetFilters: (
      state,
      action: { payload: keyof CallbacksState["filter"] }
    ) => {
      const selectedTab = action.payload;
      state.filter[selectedTab] = { ...initialFilterState };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTodayAllCallbacks.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(fetchTodayAllCallbacks.fulfilled, (state: any, action: any) => {
        const flatData = _.flatMap(action.payload, (entry) => entry.data);

        // Sort data by a field (e.g., 'createdAt') to maintain order
        const sortedData = _.orderBy(flatData, ["createdAt"], ["asc"]);

        const groupedByPrefer = _.groupBy(sortedData, (item) => {
          const prefer = item.prefer;
          if (prefer === "morning") return "morning";
          if (prefer === "afternoon") return "afternoon";
          if (prefer === "evening") return "evening";
          if (prefer === "anytime") return "anytime";
          return "Other";
        });

        state.loading = false;
        state.data = {
          Morning: groupedByPrefer["morning"] || [],
          Afternoon: groupedByPrefer["afternoon"] || [],
          Evening: groupedByPrefer["evening"] || [],
          Anytime: groupedByPrefer["anytime"] || [],
        };
        state.pagination.totalRows = action.payload;

        const totalPages = _.flatMap(
          action.payload,
          (entry) => entry.totalPages
        );
        state.pagination.totalRows = totalPages;
      })
      .addCase(fetchTodayAllCallbacks.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchCompletedCallbacks.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(fetchCompletedCallbacks.fulfilled, (state, action) => {
        state.loading = false;
        state.completedData = action.payload.data;
        state.pagination.totalRows = action.payload.totalPages;
      })
      .addCase(fetchCompletedCallbacks.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchUnattendedCallbacks.pending, (state: any) => {
        state.loading = true;
      })
      .addCase(
        fetchUnattendedCallbacks.fulfilled,
        (state: any, action: any) => {
          state.loading = false;
          state.unattendedData = action.payload.data;
          state.pagination.totalRows = action.payload.totalPages;
        }
      )
      .addCase(fetchUnattendedCallbacks.rejected, (state: any, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const {
  setPage,
  setRowPerPage,
  setFilter,
  resetFilters,
  resetPagination,
} = callbackSlice.actions;

const { reducer } = callbackSlice;

export default reducer;
